import React, { useEffect, useState } from 'react';
import OurLayouts from "../Layouts";
import { PrimeReactProvider } from "primereact/api";
import Header from "../Shared/header";
import NavBar from "../NavigationBar/NavBar";
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Modal,
  Button,
  Box,
  Pagination,
} from '@mui/material';
import NewCount from "../../assets/images/NewCount.png"
import RightArrow from "../../assets/images/Arrow.svg"
import confirmImg from '../../assets/images/View Your Task/Procedure Confirmed.svg'
// import billingConfirmed from "../assets/images/billingConfirmed.svg"
import billingConfirmed from "../../assets/images/billingConfirmed.svg"
import RejectImg from '../../assets/images/View Your Task/Rejected.svg'
import apiService from "../../services/apiservice";
import { FaEye } from 'react-icons/fa';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import AddEnquiry from '../serviceEnquiry/addenquiry';
import AssignEnquiry from '../serviceEnquiry/assign';
import AddService from '../serviceEnquiry/addService';
import FilterEnquiry from '../serviceEnquiry/filterEnquiry';
import { Calendar } from 'primereact/calendar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { FaHandPaper } from 'react-icons/fa';
import Assigned from '../../assets/images/View Your Task/Assigned.svg'
import Underprocess from '../../assets/images/View Your Task/Under Process.svg'
import waitForApprove from '../../assets/images/View Your Task/Waiting for Approval.svg'
import hold from '../../assets/images/View Your Task/Hold.svg'
import billingDone from '../../assets/images/View Your Task/Billing Done.svg'
import billingUploaded from '../../assets/images/View Your Task/Billing Uploaded.svg'
import billingApproved from '../../assets/images/View Your Task/Billing Approved.svg'
import billingCompleted from '../../assets/images/View Your Task/Billing Completed.svg'
import billingRejected from '../../assets/images/View Your Task/Billing Rejected.svg'








const ITEMS_PER_PAGE = 10;



function Dashboard() {
  const formatDate = (date) => {
    if (!date) return "";

    const d = new Date(date);
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  };
  const convertUTCDate = (date, format) => {
    format = format || 'YYYY-MM-DD'; // YYYY-MM-DD hh:mm:ss
    const localTime = moment.utc(date).toDate();
    return moment(localTime).format(format);
  }
  const userObj = JSON.parse(localStorage.getItem('loginObj'))
  const roleCode = localStorage.getItem('userRole')
  let location
  if (roleCode == 'PRADMIN') {
    location = JSON.parse(localStorage.getItem('location'));
  }
  const [count, setCount] = useState({})
  const [billStsCount, setbillstsCount] = useState({})

  const [enquiryDetails, setEnquiryDetails] = useState([]);
  const [paginatedEnquiryDetails, setPaginatedEnquiryDetails] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [mode, setMode] = useState('');
  const [selectedRow, setselectedRow] = useState([]);
  const [viewEnquiry, setViewEnquiry] = useState(false);
  const [filterObj, setfilterObj] = useState({
    fromDate: formatDate(new Date()),
    toDate: formatDate(new Date())

  });
  const [loading, setloading] = useState(false);

  const [userType, setUsertype] = useState('');

  let newTab = activeTab
  const [page, setPage] = useState(1);
  const handleChange = (newValue) => {
    setActiveTab(newValue);
    if (newValue != 2) {
      gettabEnqList(newValue)
    }
    else {
      newTab = newValue
      getEnqList()

    }
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handleClick = (data, mode) => {
    if ((mode == 'Add' && roleCode == 'ACCADMIN')) {
      return
    }
    setMode(mode)
    setselectedRow(data)
    setViewEnquiry(true)
  }

  const handleCancel = () => {
    setViewEnquiry(false)
    setViewEnquiry(false)
    if (activeTab == 2) {
      getEnqList()
    }
    else {
      gettabEnqList(activeTab)
    }
  }
  const toggleSidebar = () => {
    setViewEnquiry(!viewEnquiry);
  };
  const search = () => {
    dashBoardCount()
    biliingStsCount()
    // if (activeTab == 2) {
    //   getEnqList()
    // }
    // else {
    //   gettabEnqList(activeTab)
    // }  
  }
  const dashBoardCount = async () => {
    try {
      let payload = {}

      var currentDate = new Date();
      payload.fromDate = convertUTCDate(new Date(filterObj.fromDate))
      payload.toDate = convertUTCDate(new Date((filterObj.toDate)))
      if (roleCode === 'CE') {
        payload.roleCode = roleCode
      }
      // if(roleCode === 'PRADMIN'){
      //   payload.locationId =location.locationId
      //   }
      const res = await apiService.dashBoardCount(payload);
      if (!res.message) {
        console.log(res.data, 'count')
        setCount(res.data[0])

      }
    } catch (error) {
      console.error('Error fetching enquiry list:', error);
    }
  };
  const biliingStsCount = async () => {
    try {
      let payload = {}

      var currentDate = new Date();
      payload.fromDate = convertUTCDate(new Date(filterObj.fromDate))
      payload.toDate = convertUTCDate(new Date((filterObj.toDate)))
      // if(roleCode === 'CE'){
      //   payload.roleCode = roleCode
      // }

      const res = await apiService.billigstatusCount(payload);
      if (!res.message) {
        console.log(res.data, 'count')
        setbillstsCount(res.data[0])

      }
    } catch (error) {
      console.error('Error fetching enquiry list:', error);
    }
  };
  const handleClick1 = (data, mode, type) => {
    if ((data.billingStatus == 2 && mode == 'Add') || (mode == 'Add' && roleCode == 'ACCADMIN')) {
      return
    }
    setMode(mode)
    setUsertype(type)

    setselectedRow(data)
    setViewEnquiry(true)
  }

  const getEnqList = async (filter) => {
    let payload = {}
    try {
      // if (filterObj) {

      //   if (filterObj?.fromDate) {
      //     payload.fromDate = convertUTCDate(filterObj.fromDate);
      //   }
      //   if (filterObj?.toDate) {
      //     payload .toDate = convertUTCDate(filterObj.toDate);
      //   }
      //   if(activeTab!=2){
      //     payload.status =11

      //   }

      // }
      // else {
      var currentDate = new Date();
      // if (activeTab != 0 || newTab != 0) {
      //   payload.fromDate = convertUTCDate(new Date(filterObj.fromDate ? filterObj.fromDate : currentDate.getTime() - 7 * 24 * 60 * 60 * 1000))
      //   payload.toDate = convertUTCDate(new Date((filterObj.toDate ? filterObj.toDate : currentDate )))
      // }

      if (newTab == 0) {
        payload.status = 11
      }
      else if (newTab == 0) {
        if (roleCode != 'CE') {
          payload.assignedUser = userObj.user.code
        }
        else {
          payload.conciergeUserCode = userObj.user.code
        } payload.roleCode = roleCode

      }
      payload.fromDate = convertUTCDate(new Date(),'MM/DD/YYYY')
      payload.toDate = convertUTCDate(new Date(),'MM/DD/YYYY')
      payload.pageNumber = 1
      payload.pageSize = 3

      let payload2 = {
        roleCode: roleCode,
        pageNumber : 1,
        pageSize :3
      }
      payload2.fromDate = convertUTCDate(new Date(),'MM/DD/YYYY')
      payload2.toDate = convertUTCDate(new Date(),'MM/DD/YYYY')
      setloading(true)
      const uploadlabOrderReportResponse = (activeTab == 0 && newTab == 0) ? await apiService.getenqCElist(payload2) : await apiService.getenqList(payload)
      if (!uploadlabOrderReportResponse.message) {
        let data = []
        if (newTab == 0) {
          uploadlabOrderReportResponse.data.forEach((element, index) => {

            // if (element.status == 11  ) {
            if (data.length < 3) {
              data.push(element)
            }
            else {
              return
            }
            // }


          });
          setEnquiryDetails(data);
          setloading(false)

        }
        else {
          uploadlabOrderReportResponse.data.forEach(element => {
            // if (element.status != 11) {
            if (data.length < 3) {

              data.push(element)
            }
            else { return }

            // }

          }); setEnquiryDetails(data);

        }
        setloading(false)

      }
    } catch (error) {
      console.error('Error fetching enquiry list:', error);
    }
  };
  const parseDate = (dateString) => {
    if (!dateString) return;
    const [month, day, year] = dateString.split("/");
    return new Date(year, month - 1, day);
  };
  const gettabEnqList = async (newValue) => {
    let data = []

    let payload = {}
    var currentDate = new Date();
      payload.fromDate = convertUTCDate(new Date(),'MM/DD/YYYY')
      payload.toDate = convertUTCDate(new Date(),'MM/DD/YYYY')

    if (newValue == 0) {
      payload.status = 11
    }
    if (newValue == 1) {
      if (roleCode != 'CE') {
        payload.assignedUser = userObj.user.code
      }
      else {
        payload.conciergeUserCode = userObj.user.code
      } payload.roleCode = roleCode
    }
    // if(roleCode === 'PRADMIN'){
    //   payload.locationId =location.locationId
    //   }
    setloading(true)
    payload.pageNumber = 1
    payload.pageSize = 3
    let payload2 = {
      roleCode: roleCode,
    pageNumber :1,
      pageSize :3
    }
    payload2.fromDate = convertUTCDate(new Date(),'MM/DD/YYYY')
    payload2.toDate = convertUTCDate(new Date(),'MM/DD/YYYY')
    try {
      const uploadlabOrderReportResponse = (newValue == 0) ? await apiService.getenqCElist(payload2) : await apiService.getenqList(payload)
      if (!uploadlabOrderReportResponse.message) {

        uploadlabOrderReportResponse.data.forEach(element => {
          if (data.length < 3) {

            data.push(element)
          }
          else { return }


        });
        setEnquiryDetails(data);
      }
      setloading(false)

    } catch (error) {
      console.error('Error fetching enquiry list:', error);
    }
  }
  const convertUTCToLocal = (date, format) => {
    if (!format) {
      format = "MM-DD-YYYY, HH:mm:ss";
    }

    // Convert UTC date to local date and time
    const localDate = moment.utc(date).local();

    return localDate.format(format);
  }
  const handleFilter = (e) => {

    //  let date= formatDate(e.target.value)
    setfilterObj({
      ...filterObj,

      [e.target.name]: formatDate(e.target.value),
    });
  }
  useEffect(() => {
    getEnqList();
  }, []);

  useEffect(() => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setPaginatedEnquiryDetails(enquiryDetails.slice(startIndex, endIndex));
  }, [enquiryDetails, page]);
  useEffect(() => {
    dashBoardCount();
    biliingStsCount()
  }, []);
  const getHeader = () => {
    return (
      <div className="font-poppins text-base  text-primary  font-bold leading-6 tracking-normal text-left">{mode === 'View' ? 'View Service Enquiry Details' : mode === 'Assign' ? 'Assign Enquiry' : mode === 'Filter' ? 'Filter Enquiry' : 'Add Service'}</div>

    )
  }
  const getconceirgeStyle = (e) => {
    let payload = {

      color: e == 1 ? '#3D3BA4' : e == 2 ? '#DD0000' : e == 3 ? '#ECAC09' : e == 4 ? '#299F5D' : '#3D3BA4',

    }
    return payload
  }
  const getStylestatus = (e) => {
    let payload = {
      border: '1px solid', // Adds a border of 1px solid
      borderColor: (e == 1 || e == 2 || e == 3 || e == 7) ? '#ECAC09' : (e == 0 || e == 4 || e == 5) ? '#DD0000' : (e == 6 || e == 8 || e == 9) ? '#299F5D' : e == 11 ? '#3D3BA4' : 'black', // Sets the border color to match the text color
      padding: '5px',
      color: (e == 1 || e == 2 || e == 3 || e == 7) ? '#ECAC09' : (e == 0 || e == 4 || e == 5) ? '#DD0000' : (e == 6 || e == 8 || e == 9) ? '#299F5D' : e == 11 ? '#3D3BA4' : 'black',
      display: 'inline-block',
      borderRadius: '5px'
    }
    return payload
  }
  return (
    <PrimeReactProvider>
      <OurLayouts >
        <Backdrop
          sx={{ color: "#FFFFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress size={80} color="inherit" />
        </Backdrop>
        <div className='overflow-auto'>
          <Button icon="pi pi-bars" onClick={toggleSidebar} />
          <Sidebar style={{ width: '60%' }} dismissable={false} visible={viewEnquiry} header={getHeader()} onHide={() => setViewEnquiry(false)} position="right"
          >
            {/* Header and Hide button on the same row */}
            {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '1rem', borderBottom: '1px solid #ccc' }}>
              <div className="font-poppins text-base font-semibold leading-6 tracking-normal text-left">{mode === 'View' ? 'View Service Enquiry Details' : mode === 'Assign' ? 'Assign to me' : mode === 'Filter' ? 'Filter Enqiury' : 'Add Service'}</div>
              <Button icon="pi pi-times" onClick={() => setViewEnquiry(false)} className="p-button-text p-button-danger p-button-sm" />
            </div> */}

            {/* Content for your sidebar */}
            {mode == 'View' && <div style={{ padding: '1rem' }}>
              <AddEnquiry selected={selectedRow} onClose={handleCancel} />
            </div>}
            {mode == 'Assign' &&
              <div style={{ padding: '1rem' }}>
                <AssignEnquiry selected={selectedRow} userType={userType} onClose={handleCancel} />

              </div>}
            {mode == 'Add' &&
              <div style={{ padding: '1rem' }}>
                <AddService selected={selectedRow} onClose={handleCancel} />

              </div>}
            {/* {mode === 'Filter' &&
              <div style={{ padding: '1rem' }}>
                <FilterEnquiry onCance={handleCancel} onClose={(e) => filterCancel(e)} />

              </div>} */}
          </Sidebar>
        </div>
        <>
          <div className="flex flex-col w-full mt-[7rem] overflow-auto" >
            <div className="flex justify-between justify-items-center">
              <div>
                <h1 className="text-2xl text-primary font-semibold mb-8">View Your Task</h1>

              </div>

            </div>
            <div className='flex items-center gap-2 mr-16 mb-3'>
              <div>
                <Calendar id="fromDate"
                  className="border border-gray-300 rounded-md p-2" placeholder='Select FromDate'
                  onChange={(e) => handleFilter(e)} value={parseDate(filterObj?.fromDate)}
                  name='fromDate' dateFormat="dd/mm/yy" style={{ height: '40px' }}
                  suffix={<i className="pi pi-calendar" style={{ fontSize: '1rem', color: '#495057' }}></i>}
                />
              </div>
              <div >
                <Calendar id="toDate"
                  className="border border-gray-300 rounded-md p-2" name='toDate' placeholder='Select ToDate'
                  onChange={(e) => handleFilter(e)} value={parseDate(filterObj?.toDate)}
                  dateFormat="dd/mm/yy" style={{ height: '40px' }}
                  minDate={parseDate(filterObj.fromDate)}
                />
              </div>
              <button className="w-32 h-9 bg-[#004172] text-white rounded-full"
                onClick={search}
              >
                Search
              </button>
            </div>
            <div className="w-[99%] h-auto rounded-[10px] " style={{ boxShadow: "0px 8px 25px 0px rgba(0, 0, 0, 0.06)" }}>
              <div className={roleCode == 'CE' ? "grid grid-cols-7 gap-5 mb-10 mt-5 mr-3" : "grid grid-cols-6 gap-5 mb-10 mt-5 mr-3"}>
                {roleCode == 'CE' &&
                  <div className=" rounded-[10px] bg-[#BACBFF] bg-opacity-[0.48]">
                    <div className="mt-5 ml-5 flex">
                      <img className="w-5 h-5" src={Assigned} />
                      <h1 className="mb-5 ml-5 font-bold ">{'New'}</h1>
                    </div>
                    {/* <div className="flex  justify-end ml-2"> */}
                    <p className="text-2xl justify-end  flex mr-5 mt-5  font-bold">{count?.newCount}</p>
                    {/* <img className="w-[40.08px] mr-5 mb-2 h-[40.08px]" src={RightArrow} /> */}

                    {/* </div> */}
                  </div>
                }
                <div className=" rounded-[10px] bg-[#BACBFF] bg-opacity-[0.48]">
                  <div className="mt-5 ml-5 flex">
                    <img className="w-5 h-5" src={Assigned} />
                    <h1 className="mb-5 ml-5 font-bold ">{roleCode == 'CE' ? 'Assigned' : 'New'}</h1>
                  </div>
                  {/* <div className="flex  justify-end ml-2"> */}
                  <p className="text-2xl justify-end  flex mr-5 mt-5  font-bold">{roleCode == 'CE' ? count.assignedCount : count?.newCount}</p>
                  {/* <img className="w-[40.08px] mr-5 mb-2 h-[40.08px]" src={RightArrow} /> */}

                  {/* </div> */}
                </div>

                <div className=" rounded-[10px] bg-[#FDECC2] bg-opacity-[0.64]">
                  <div className="mt-5 ml-5 flex justify-item-center">
                    {/* <div className="bg-white justify-content-start gap-2 justify-item-center rounded"> */}
                    <img className="w-5 h-5 " src={Underprocess} />
                    <h1 className="mb-5 ml-5 font-bold ">Under Process</h1></div>
                  {/* </div> */}
                  {/* <div className="flex  justify-between ml-2"> */}
                  <p className="text-2xl justify-end  flex mr-5  font-bold">{count?.underProcessCount}</p>
                  {/* <img className="w-[40.08px] mr-5 mb-2 h-[40.08px]" src={RightArrow} /> */}

                  {/* </div> */}
                </div>
                <div className=" rounded-[10px] bg-[#05E200] bg-opacity-[0.33]">
                  <div className="mt-5 ml-5 flex justify-item-center">
                    {/* <div className="bg-white justify-content-start gap-2 justify-item-center rounded"> */}
                    <img className="w-5 h-5" src={confirmImg} />
                    <h1 className="mb-5 ml-5 font-bold ">Procedure Confirmed</h1></div>
                  {/* </div> */}
                  {/* <div className="flex  justify-between ml-2"> */}
                  <p className="text-2xl justify-end  flex mr-5  font-bold">{count?.procedureConfirmedCount}</p>
                  {/* <img className="w-[40.08px] mr-5 mb-2 h-[40.08px]" src={RightArrow} /> */}

                  {/* </div> */}
                </div>
                <div className=" rounded-[10px] bg-[#BDFFBC] bg-opacity-[0.64]">
                  <div className="mt-5 ml-5 flex justify-item-center">
                    {/* <div className="bg-white justify-content-start gap-2 justify-item-center rounded"> */}
                    <img className="w-5 h-5" src={waitForApprove} />
                    <h1 className="mb-5 ml-5 font-bold ">Waiting for Approval</h1></div>
                  {/* </div> */}
                  {/* <div className="flex  justify-between ml-2"> */}
                  <p className="text-2xl justify-end  flex mr-5  font-bold">{count?.waitingforApproval}</p>
                  {/* <img className="w-[40.08px] mr-5 mb-2 h-[40.08px]" src={RightArrow} /> */}

                  {/* </div> */}
                </div>
                <div className=" rounded-[10px] bg-[#DD0000] bg-opacity-[0.10]">
                  <div className="mt-5 ml-5 flex justify-item-center">
                    {/* <div className="bg-white justify-content-start gap-2 justify-item-center rounded"> */}
                    <img className="w-5 h-5" src={RejectImg} />
                    <h1 className="mb-5 ml-5 font-bold ">Rejected</h1></div>
                  {/* </div> */}
                  {/* <div className="flex  justify-between ml-2"> */}
                  <p className="text-2xl justify-end mt-5 flex mr-5  font-bold">{count?.rejectedCount}</p>
                  {/* <img className="w-[40.08px] mr-5 mb-2 h-[40.08px]" src={RightArrow} /> */}

                  {/* </div> */}
                </div>

                <div className=" rounded-[10px] bg-[#FDECC2] bg-opacity-[0.64]">
                  <div className="mt-5 ml-5 flex justify-item-center">
                    {/* <div className="bg-white justify-content-start gap-2 justify-item-center rounded"> */}
                    <img className="w-5 h-5" src={hold} />
                    <h1 className="mb-5 ml-5 mt-5 font-bold "> Hold</h1></div>
                  {/* </div> */}
                  {/* <div className="flex  justify-between ml-2"> */}
                  <p className="text-2xl justify-end  flex mr-5  font-bold">{count?.holdCount}</p>
                  {/* <img className="w-[40.08px] mr-5 mb-2 h-[40.08px]" src={RightArrow} /> */}

                  {/* </div> */}
                </div>
              </div>
              <div className={roleCode == 'CE' ? "grid grid-cols-7 gap-5 mb-10 mt-5 mr-3" : "grid grid-cols-6 gap-5 mb-10 mt-5 mr-3"}>
              <div className=" rounded-[10px] bg-[#A437D8] bg-opacity-[0.29]">
                  <div className="mt-5 ml-5 flex">
                    <img className="w-5 h-5" src={billingUploaded} />
                    <h1 className="mb-5 ml-5 font-bold ">Billing Uploaded</h1>
                  </div>
                  {/* <div className="flex  justify-end ml-2"> */}
                  <p className="text-2xl justify-end  flex mr-5 mt-5  font-bold">{billStsCount?.billingUploaded}</p>
                  {/* <img className="w-[40.08px] mr-5 mb-2 h-[40.08px]" src={RightArrow} /> */}

                  {/* </div> */}
                </div>
                
                <div className=" rounded-[10px] bg-[#C4FFBA] bg-opacity-[0.48]">
                  <div className="mt-5 ml-5 flex justify-item-center">
                    {/* <div className="bg-white justify-content-start gap-2 justify-item-center rounded"> */}
                    <img className="w-5 h-5 " src={billingDone} />
                    <h1 className="mb-5 ml-5 font-bold ">Billing Done</h1></div>
                  {/* </div> */}
                  {/* <div className="flex  justify-between ml-2"> */}
                  <p className="text-2xl justify-end  flex mr-5  font-bold">{billStsCount?.billingDone}</p>
                  {/* <img className="w-[40.08px] mr-5 mb-2 h-[40.08px]" src={RightArrow} /> */}

                  {/* </div> */}
                </div>
               

                <div className=" rounded-[10px] bg-[#3D3BA4] bg-opacity-[0.15]">
                  <div className="mt-5 ml-5 flex justify-item-center">
                    {/* <div className="bg-white justify-content-start gap-2 justify-item-center rounded"> */}
                    <img className="w-5 h-5" src={billingCompleted} />
                    <h1 className="mb-5 ml-5 font-bold ">Billing Completed</h1></div>
                  {/* </div> */}
                  {/* <div className="flex  justify-between ml-2"> */}
                  <p className="text-2xl justify-end  flex mr-5  font-bold">{billStsCount?.billingCompleted}</p>
                  {/* <img className="w-[40.08px] mr-5 mb-2 h-[40.08px]" src={RightArrow} /> */}

                  {/* </div> */}
                </div>
                <div className=" rounded-[10px] bg-[#05E200] bg-opacity-[0.33]">
                  <div className="mt-5 ml-5 flex justify-item-center">
                    {/* <div className="bg-white justify-content-start gap-2 justify-item-center rounded"> */}
                    <img className="w-5 h-5" src={billingApproved} />
                    <h1 className="mb-5 ml-5 font-bold ">Billing Approved</h1></div>
                  {/* </div> */}
                  {/* <div className="flex  justify-between ml-2"> */}
                  <p className="text-2xl justify-end  flex mr-5  font-bold">{billStsCount?.billingApproved}</p>
                  {/* <img className="w-[40.08px] mr-5 mb-2 h-[40.08px]" src={RightArrow} /> */}

                  {/* </div> */}
                </div>
                <div className=" rounded-[10px] bg-[#DD0000] bg-opacity-[0.10]">
                  <div className="mt-5 ml-5 flex justify-item-center">
                    {/* <div className="bg-white justify-content-start gap-2 justify-item-center rounded"> */}
                    <img className="w-5 h-5" src={billingRejected} />
                    <h1 className="mb-5 ml-5 font-bold ">Billing Rejected</h1></div>
                  {/* </div> */}
                  {/* <div className="flex  justify-between ml-2"> */}
                  <p className="text-2xl justify-end mt-5 flex mr-5  font-bold">{billStsCount?.billingRejected}</p>
                  {/* <img className="w-[40.08px] mr-5 mb-2 h-[40.08px]" src={RightArrow} /> */}

                  {/* </div> */}
                </div>
                <div className=" rounded-[10px] bg-[#DD0000] bg-opacity-[0.10]">
                  <div className="mt-5 ml-5 flex justify-item-center">
                    {/* <div className="bg-white justify-content-start gap-2 justify-item-center rounded"> */}
                    <img className="w-5 h-5" src={Assigned} />
                    <h1 className="mb-5 ml-5 font-bold ">Total Billing</h1></div>
                  {/* </div> */}
                  {/* <div className="flex  justify-between ml-2"> */}
                  <p className="text-2xl justify-end mt-5 flex mr-5  font-bold">{billStsCount?.totalCount}</p>
                  {/* <img className="w-[40.08px] mr-5 mb-2 h-[40.08px]" src={RightArrow} /> */}

                  {/* </div> */}
                </div>
              </div>
              <Tabs className='mt-10' value={activeTab} onChange={(event, newValue) => handleChange(newValue)}>
                <Tab label={<span style={{ fontWeight: 'bold' }}>New</span>} />
                <Tab disabled={(roleCode != 'CE' && roleCode != 'GROUPPRADMIN')} label={<span style={{ fontWeight: 'bold' }}>{(roleCode != 'CE' && roleCode != 'GROUPPRADMIN') ? '' : 'Assign To Me'}</span>} />
                <Tab label={<span style={{ fontWeight: 'bold' }}>ALL</span>} />

              </Tabs>
              <div className='w-full'>
                {activeTab == 2 && <TableContainer component={Paper} className="print-table" style={{ width: '100%', overflowX: 'scroll' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Enquiry ID</TableCell>

                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Patient ID</TableCell>

                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Patient Name</TableCell>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Enquiry Date</TableCell>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Eclinic  Name</TableCell>

                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Eclinic Location</TableCell>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Email Id</TableCell>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Swasthya Mithra</TableCell>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Mobile Number</TableCell>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Communication Number</TableCell>

                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Preferred Hospital & eClinic</TableCell>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Concierge Name</TableCell>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Concierge Status</TableCell>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Status</TableCell>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Reason For Hold</TableCell>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Reason For Reject</TableCell>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Status Date</TableCell>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>View Enquiry Details</TableCell>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Billing Count</TableCell>
                        <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Action</TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedEnquiryDetails.map((notifi) => (
                        <TableRow key={notifi.id}>
                          <TableCell style={{ fontSize: '17px', fontWeight: 500 }}>{notifi.id}</TableCell>

                          <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.patientId}</TableCell>

                          <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.name}</TableCell>
                          <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{convertUTCToLocal(notifi.createdDate)}</TableCell>

                          <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.chehospitalName || ''}</TableCell>
                          <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.chelocationName}</TableCell>
                          <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.emailId}</TableCell>
                          <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.swasthUserName ? notifi.swasthUserName : 'NA'}</TableCell>
                          <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.contactNumber ? notifi.contactNumber : ''}</TableCell>
                          <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.communicationMobile ? notifi.communicationMobile : ''}</TableCell>

                          <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.hospitalName}</TableCell>
                          <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.conciergeName || ''}</TableCell>
                          <TableCell style={{ fontSize: '15px', fontWeight: 500 }}><span style={getconceirgeStyle(notifi.conciergeStatus)}>{notifi.conciergeStatus == 1 ? 'Patient Admitted' : notifi.conciergeStatus == 2 ? 'Patient Denied' : notifi.conciergeStatus == 3 ? 'Patient iN OT' : notifi.conciergeStatus == 4 ? 'Patient Discharged' : ''}</span></TableCell>
                          <TableCell style={{ fontSize: '15px', fontWeight: 500, width: '10%' }} ><span style={getStylestatus(notifi.status)}>{notifi.status == 0 ? 'Rejected' : notifi.status == 1 ? 'Under Process' : notifi.status == 6 ? 'Procedure Confirmed'
                            : notifi.status == 5 ? 'Hospital Denied' : notifi.status == 2 ? 'Discussing with Hospital' : notifi.status == 3 ? 'Discussing with Patient' : notifi.status == 4 ? 'Patient Denied' : notifi.status == 12 ? 'Waiting For Approval' : notifi.status == 7 ? 'Hold' : notifi.status == 11 ? 'New' : ''
                          }</span></TableCell>
                          {notifi.status == 7 ? <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.reasonForHold}</TableCell> : <TableCell></TableCell>}
                          {notifi.status == 0 ? <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.reasonForReject}</TableCell> : <TableCell></TableCell>}
                          <TableCell>{notifi?.patientServiceEnquiryFormStatusLogList ? convertUTCToLocal(notifi?.patientServiceEnquiryFormStatusLogList[0]?.statusDate) : ''}</TableCell>

                          <TableCell style={{ fontSize: '15px', fontWeight: 500 }}><FaEye onClick={(e) => handleClick(notifi, 'View')} /></TableCell>
                          <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >

                              <FaEye
                                // onClick={() => onCountView(notifi)}
                              />
                            </TableCell>
                          {(notifi.status == 6 && notifi.conciergeStatus == 4) && notifi.billingStatus != 2 && (<TableCell onClick={(e) => handleClick(notifi, 'Add')} disabled={notifi.billingStatus === 2} style={{ fontSize: '15px', fontWeight: 500., width: '10%' }} >
                            Add Service +
                          </TableCell>)}
                          {(notifi.status == 6 && notifi.conciergeStatus == 4) && notifi.billingStatus == 2 && roleCode == 'ACCADMIN' && (<TableCell style={{ fontSize: '15px', fontWeight: 500., width: '10%' }} >
                            Add Service +
                          </TableCell>)}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>}
                {activeTab == 0 &&
                  <TableContainer component={Paper} style={{ width: '100%', overflowX: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Enquiry ID</TableCell>

                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Patient ID</TableCell>

                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Patient Name</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Enquiry Date</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Eclinic  Name</TableCell>

                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Eclinic Location</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Email Id</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Swasthya Mithra</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Mobile Number</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Communication Number</TableCell>

                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Preferred Hospital & eClinic</TableCell>

                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Status</TableCell>

                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Status Date</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>View Enquiry Details</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Excecutive </TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Backoffice </TableCell>                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paginatedEnquiryDetails.map((notifi) => (
                          <TableRow key={notifi.id}>
                            <TableCell style={{ fontSize: '17px', fontWeight: 500 }}>{notifi.id}</TableCell>

                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.patientId}</TableCell>

                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.name}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{convertUTCToLocal(notifi.createdDate)}</TableCell>

                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.chehospitalName || ''}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.chelocationName}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.emailId}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.swasthUserName ? notifi.swasthUserName : 'NA'}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.contactNumber ? notifi.contactNumber : ''}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.communicationMobile ? notifi.communicationMobile : ''}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.hospitalName}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500, width: '10%' }} ><span style={getStylestatus(notifi.status)}>{notifi.status == 0 ? 'Rejected' : notifi.status == 1 ? 'Under Process' : notifi.status == 6 ? 'Procedure Confirmed'
                              : notifi.status == 5 ? 'Hospital Denied' : notifi.status == 2 ? 'Discussing with Hospital' : notifi.status == 3 ? 'Discussing with Patient' : notifi.status == 4 ? 'Patient Denied' : notifi.status == 12 ? 'Waiting For Approval' : notifi.status == 7 ? 'Hold' : notifi.status == 11 ? 'New' : ''
                            }</span></TableCell>
                            <TableCell>{notifi?.patientServiceEnquiryFormStatusLogList ? convertUTCToLocal(notifi?.patientServiceEnquiryFormStatusLogList[0]?.statusDate) : ''}</TableCell>

                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}><FaEye onClick={(e) => handleClick(notifi, 'View')} /></TableCell>
                            {roleCode != 'ACCADMIN' ? <TableCell>     <button className="w-36 h-9  bg-[#004172] text-white  rounded-full"
                              onClick={(e) => handleClick1(notifi, 'Assign', 'CE')}>
                              Executive Assign
                            </button></TableCell> : <TableCell ></TableCell>}
                            {roleCode != 'ACCADMIN' && roleCode != 'CE' ? <TableCell>     <button className="w-36 h-9  bg-[#004172] text-white  rounded-full"
                              onClick={(e) => handleClick1(notifi, 'Assign', 'BO')}>
                              Back Office Assign
                            </button></TableCell> : <TableCell ></TableCell>}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>}
                {activeTab == 1 &&
                  <TableContainer component={Paper} style={{ width: '100%', overflowX: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Enquiry ID</TableCell>

                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Patient ID</TableCell>

                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Patient Name</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Enquiry Date</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Eclinic  Name</TableCell>

                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Eclinic Location</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Email Id</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Swasthya Mithra</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Mobile Number</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Communication Number</TableCell>

                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Preferred Hospital & eClinic</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Concierge Name</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Concierge Status</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Status</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Reason For Hold</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Reason For Reject</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Status Date</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>View Enquiry Details</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Billing Count</TableCell>

                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Action</TableCell>
                          <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Excecutive </TableCell>
                          {/* <TableCell style={{ fontSize: '17px', fontWeight: 600 }}>Backoffice </TableCell>    */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paginatedEnquiryDetails.map((notifi) => (
                          <TableRow key={notifi.id}>
                            <TableCell style={{ fontSize: '17px', fontWeight: 500 }}>{notifi.id}</TableCell>

                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.patientId}</TableCell>

                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.name}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{convertUTCToLocal(notifi.createdDate)}</TableCell>

                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.chehospitalName || ''}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.chelocationName}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.emailId}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.swasthUserName ? notifi.swasthUserName : 'NA'}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.contactNumber ? notifi.contactNumber : ''}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.communicationMobile ? notifi.communicationMobile : ''}</TableCell>

                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.hospitalName}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.conciergeName || ''}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}><span style={getconceirgeStyle(notifi.conciergeStatus)}>{notifi.conciergeStatus == 1 ? 'Patient Admitted' : notifi.conciergeStatus == 2 ? 'Patient Denied' : notifi.conciergeStatus == 3 ? 'Patient iN OT' : notifi.conciergeStatus == 4 ? 'Patient Discharged' : ''}</span></TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500, width: '10%' }} ><span style={getStylestatus(notifi.status)}>{notifi.status == 0 ? 'Rejected' : notifi.status == 1 ? 'Under Process' : notifi.status == 6 ? 'Procedure Confirmed'
                              : notifi.status == 5 ? 'Hospital Denied' : notifi.status == 2 ? 'Discussing with Hospital' : notifi.status == 3 ? 'Discussing with Patient' : notifi.status == 4 ? 'Patient Denied' : notifi.status == 12 ? 'Waiting For Approval' : notifi.status == 7 ? 'Hold' : notifi.status == 11 ? 'New' : ''
                            }</span></TableCell>
                            {notifi.status == 7 ? <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.reasonForHold}</TableCell> : <TableCell></TableCell>}
                            {notifi.status == 0 ? <TableCell style={{ fontSize: '15px', fontWeight: 500 }}>{notifi.reasonForReject}</TableCell> : <TableCell></TableCell>}

                            <TableCell>{notifi?.patientServiceEnquiryFormStatusLogList ? convertUTCToLocal(notifi?.patientServiceEnquiryFormStatusLogList[0]?.statusDate) : ''}</TableCell>
                            <TableCell style={{ fontSize: '15px', fontWeight: 500 }}><FaEye onClick={(e) => handleClick(notifi, 'View')} /></TableCell>
                            <TableCell
                              style={{ fontSize: "15px", fontWeight: 500 }}
                            >

                              <FaEye
                                // onClick={() => onCountView(notifi)}
                              />
                            </TableCell>
                            {((notifi.status == 6 && notifi.conciergeStatus == 4) && notifi.billingStatus != 2) ? <TableCell style={{ fontSize: '15px', fontWeight: 500, width: '10%' }} onClick={(e) => handleClick(notifi, 'Add')} disabled={notifi.billingStatus === 2}    >
                              Add Service +
                            </TableCell> : ((notifi.status == 6 && notifi.conciergeStatus == 4) && notifi.billingStatus == 2 && roleCode == 'ACCADMIN') ? <TableCell style={{ fontSize: '15px', fontWeight: 500, width: '10%' }} disabled={notifi.billingStatus === 2}    >
                              Add Service +
                            </TableCell> : <TableCell></TableCell>}
                            {(notifi.status != 0 && notifi.status != 7) ? <TableCell>
                              <button className="w-36 h-9  bg-[#004172] text-white  rounded-full"
                                onClick={(e) => handleClick1(notifi, 'Assign', 'CE')}>
                                Executive Assign
                              </button></TableCell> : <TableCell></TableCell>}
                            {/* <TableCell>     <button className="w-36 h-9  bg-[#004172] text-white  rounded-full"
                              onClick={(e) => handleClick1(notifi, 'Assign','BO')}>
                              Back Office Assign
                            </button></TableCell>   */}

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>}
                {/* {enquiryDetails.length > ITEMS_PER_PAGE && ( */}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                  <Pagination
                    count={Math.ceil(enquiryDetails.length / ITEMS_PER_PAGE)}
                    page={page}
                    color="secondary"
                    onChange={handlePageChange}
                  />
                </Box>
                {/* )} */}
              </div>

            </div>
            {/* <div className="w-[95%] h-[600px] mt-8 ml-8 rounded-[5px]" style={{ boxShadow: "0px 8px 25px 0px rgba(0, 0, 0, 0.06)" }}>
          
            </div> */}

          </div>

        </>
      </OurLayouts>
    </PrimeReactProvider >
  );
}

export default Dashboard;
